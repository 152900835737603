/* Swiper
**************************************************************/
import Swiper from "swiper";
import {FreeMode, Mousewheel} from 'swiper/modules';

let swiper = Swiper;
let init = false;

export function episodeSwiper() {
    if (!init) {
        init = true;
        swiper = new Swiper('#episodeSwiper', {
            enabled: true,
            modules: [FreeMode, Mousewheel],
            freeMode: true,
            mousewheel: {
                enabled: true,
                releaseOnEdges: true,
            },
            touchReleaseOnEdges: true,
            direction: "vertical",
            slidesPerView: 5,
            wrapperClass: 'games-wrapper',
            slideClass: 'game-link-wrapper',
            on: {
                init: function () {
                    const host = this.hostEl;
                    host.classList.remove('no-init-episode-swiper');
                },
            },
        });
    }
}
