/* Swiper
**************************************************************/
import Swiper from "swiper";

let swiper = Swiper;
let init = false;

/* Медиа запросы
**************************************************************/
export function featuresSwiper() {
    let small = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
    let medium = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
    let large = window.matchMedia('(min-width: 1024px) and (max-width: 1199px)');
    let xlarge = window.matchMedia('(min-width: 1200px)');

    // Активно только для мобильных устройств
    if(small.matches) {
        if (!init) {
            init = true;
            swiper = new Swiper('#featuresSwiper', {
                width: window.innerWidth - 40,
                freeMode: true,
                slidesPerView: 1,
                spaceBetween: 0,
                enabled: true,
                breakpoints: {
                    375: {
                        width: 335,
                    },
                    768: {
                        width: null
                    },
                }

            });
        }
    }

    // Выключено для плашетов
    else if(medium.matches && typeof swiper.destroy === "function") {
        swiper.destroy(false);
        init = false;
    }

    // Выключено для плашетов и маленьких экранов
    else if(large.matches && typeof swiper.destroy === "function") {
        swiper.destroy(false);
        init = false;
    }

    // Выключено для больших экранов
    else if(xlarge.matches && typeof swiper.destroy === "function") {
        swiper.destroy(false);
        init = false;
    }
}
