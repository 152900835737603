/* Swiper
**************************************************************/
import Swiper from "swiper";
import {Navigation} from 'swiper/modules';

let swiper = Swiper;
let init = false;

function updateNavButtonPosition(swiperInstance) {
    const el = swiperInstance.el;
    const currentIndex = swiperInstance.activeIndex;
    const currentSlideElement = swiperInstance.slides[currentIndex];
    const teamCoverElement = currentSlideElement.querySelector('.team-cover');
    const teamNavButtons = el.querySelector('.team-nav-buttons');
    if (teamCoverElement && teamNavButtons) {
        const {height} = teamCoverElement.getBoundingClientRect();
        teamNavButtons.style.top = `${height + 24}px`;
    }
}

export function teamSwiper() {
    if (!init) {
        init = true;
        swiper = new Swiper('#teamSwiper', {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 10,
            initialSlide: 1,
            centeredSlides: true,
            slideToClickedSlide: true,
            loop: true,
            loopAdditionalSlides: 3,
            loopAddBlankSlides: true,
            loopPreventsSliding: true,
            speed: 1000,
            navigation: {
                nextEl: '.team-nav-next',
                prevEl: '.team-nav-prev'
            },
            breakpoints: {
                768: {
                    spaceBetween: 32,
                },
                1024: {
                    spaceBetween: 64,
                },
                1200: {
                    spaceBetween: 56,
                }
            },
            on: {
                init: function () {
                    const host = this.hostEl;
                    const currentIndex = this.activeIndex;
                    const currentSlideElement = this.slides[currentIndex];
                    const teamCoverImgElement = currentSlideElement.querySelector('.team-cover img');
                    host.classList.remove('no-init-team-swiper');
                    host.classList.add('team-swiper-initialized');
                    if (teamCoverImgElement) {
                        const {width, height} = teamCoverImgElement.getBoundingClientRect();
                        this.slides.forEach((slide, index) => {
                            const teamCoverElement = slide.querySelector('.team-cover');
                            if (teamCoverElement) {
                                teamCoverElement.style.width = `${width}px`;
                                teamCoverElement.style.height = `${height}px`;
                            }
                        })
                    }
                },
                slideChange: function () {
                    updateNavButtonPosition(this);
                },
                resize: function () {
                    updateNavButtonPosition(this);
                },
            },
        });
    }
}
