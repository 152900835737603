import './menu.js'
import {menu} from "./menu.js";
import {featuresSwiper} from "./featuresSwiper.js";
import {teamSwiper} from "./teamSwiper.js";
import {episodeSwiper} from "./episodeSwiper.js";
import {initYouTubeVideos} from "./initYouTubeVideos.js";

export function app() {
    // Инициализация меню
    menu();
    initYouTubeVideos();

    // Инициализация функций на событие загрузки страницы
    window.addEventListener('load', function () {
        featuresSwiper();
        teamSwiper();
        episodeSwiper();
    });

    // Инициализация функций на событие изменения размера окна
    window.addEventListener('resize', function () {
        featuresSwiper();
        teamSwiper();
        episodeSwiper();
    });
}
