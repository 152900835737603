export function menu() {
    handleClickMenuButton();
    handleClickMenuItem();
}

function handleClickMenuItem() {
    const menuItems = document.querySelectorAll('.menu-link');
    const preHeader = document.getElementById('preHeader');
    const body = document.body;
    if (menuItems.length > 0) {
        menuItems.forEach(function (menuItem) {
            menuItem.addEventListener('click', function (e) {
                e.preventDefault();
                const hashTarget = this.hash;
                const scrollToElement = document.querySelector(hashTarget);
                if (scrollToElement) {
                    body.classList.remove('mobile-menu-opened');
                    preHeader.classList.remove('pre-header--opened');
                    scrollToElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        });
    }
}

function handleClickMenuButton() {
    const menuButton = document.getElementById('menuButton');
    const preHeader = document.getElementById('preHeader');
    const body = document.body;
    const html = document.documentElement;

    menuButton.addEventListener('click', function (e) {
        e.preventDefault();
        preHeader.classList.toggle('pre-header--opened');
        body.classList.toggle('mobile-menu-opened');
        html.classList.toggle('html-mobile-menu-opened');
    });
}
