export function initYouTubeVideos() {
    const parentElement = document.querySelector('.youtube-player');
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', '');
    const videoId = parentElement.getAttribute('data-id');
    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
    parentElement.appendChild(iframe);
}
